import React, { useEffect, useState } from 'react';
import Button from 'components/button/button';
import DashboardLayout from '../../layouts/dashboard-layout';
import { Toggle } from 'react-powerplug';
import getRandomColor from 'helpers/get-random-color';
import useAuth from 'domain/auth/hooks/use-auth';
import useQsysDeviceSubscription from './hooks/use-qsys-device-subscription';
import QsysModal from './qsys-modal';
import { ReactComponent as IcoDown } from 'assets/svg-icons/ico-down.svg';
import { ReactComponent as IcoUp } from 'assets/svg-icons/ico-up.svg';
import CoreShowDetails from './components/core-show-details';
const QsysZonesScreen = ({ qsysDevices }) => {
  const { user } = useAuth();
  const { qsysDevicesSubscription, qsysDevicesSubscriptionLoading } =
    useQsysDeviceSubscription();

  useEffect(() => {
    if (!qsysDevicesSubscriptionLoading) {
      const updatedDevice =
        qsysDevicesSubscription?.QsysDeviceSubscription?.node;
      const updatedDevices = [...qsysDevices];
      const deviceIndex = updatedDevices.findIndex(
        (d) => d.id === updatedDevice?.id
      );
      if (deviceIndex !== -1) {
        updatedDevices[deviceIndex] = {
          ...updatedDevices[deviceIndex],
          zones: updatedDevice.zones || [],
        };
      }
    }
  }, [qsysDevicesSubscription]);

  return (
    <DashboardLayout
      subtitle="Curator"
      title={'Q-SYS Music streams'}
      modifier="devices"
      showSwitcher
    >
      <div
        style={{
          height: 80,
          backgroundColor: '#393B5A',
          borderRadius: 3,
          padding: 20,
          gap: 5,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 30,
        }}
      >
        <p style={{ fontWeight: 'bold' }}>
          PIN:{' '}
          {!user
            ? 'Loading'
            : user?.qsys?.pin
            ? user?.qsys?.pin?.toString().slice(0, 3) +
              '-' +
              user?.qsys?.pin?.toString().slice(3, 6)
            : 'PIN unavailable'}
        </p>
        <p>Use this to activate your Q-SYS plugin.</p>
      </div>

      {qsysDevices.map((device, deviceIndex) => (
        <Toggle key={device.id}>
          {({ on: onDropDown, toggle: toggleDropDown }) => (
            <div className="core-container" style={{ marginBottom: 70 }}>
              <div
                className="core-header"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: 20,
                }}
              >
                <div>
                  <h3 style={{ fontSize: 20, marginBottom: 5, marginLeft: 4 }}>
                    {device.name || `Core ${deviceIndex + 1}`}
                  </h3>

                  <div style={{ marginLeft: 4 }}>
                    <Button
                      modifier="as-link"
                      onClick={toggleDropDown}
                      style={{
                        fontSize: 16,
                        textDecoration: 'underline',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      Show details
                      {onDropDown ? (
                        <IcoUp style={{ width: 12, height: 12 }} />
                      ) : (
                        <IcoDown style={{ width: 12, height: 12 }} />
                      )}
                    </Button>
                  </div>
                </div>
                <Toggle>
                  {({ on, toggle }) => (
                    <>
                      <Button
                        modifier="as-link"
                        onClick={toggle}
                        style={{
                          fontSize: 16,
                          marginRight: 5,
                          marginTop: 5,
                        }}
                      >
                        Edit streams
                      </Button>
                      <QsysModal
                        on={on}
                        toggle={toggle}
                        zones={device.zones || []}
                        deviceId={device.id}
                        noOfZones={device.noOfZones}
                      />
                    </>
                  )}
                </Toggle>
              </div>
              {onDropDown && <CoreShowDetails device={device} />}
              <div className="devices">
                <div className="devices__head" style={{ marginBottom: 20 }}>
                  <div className="subtitle" style={{ marginTop: 0 }}>
                    <h4 style={{ fontSize: 16 }}>
                      Active streams: {device.zones?.length || 0}
                    </h4>
                  </div>
                </div>

                <div className="devices__body">
                  {device.zones?.map((zone, index) => (
                    <div
                      key={zone?.zoneId}
                      className="device"
                      style={{
                        backgroundColor:
                          zone?.reason === 'play'
                            ? getRandomColor(index % 15)
                            : 'grey',
                      }}
                    >
                      <Button
                        to={{
                          pathname: `/qsys/core/${device.id}/zone/${zone?.zoneId}`,
                        }}
                        className="device__link"
                      />

                      <div className="device__head">
                        <h3>{zone?.zoneName}</h3>
                      </div>

                      <div className="device__body">
                        <h4>{zone?.currentTitle}</h4>
                        <h5>{zone?.currentArtist}</h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Toggle>
      ))}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          marginTop: 90,
          paddingBottom: 50,
        }}
      >
        <p style={{ fontWeight: 'bold' }}>Need help?</p>
        <p>
          Visit{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://support.activaire.com/q-sys"
          >
            https://support.activaire.com/q-sys
          </a>
        </p>
      </div>
    </DashboardLayout>
  );
};

export default QsysZonesScreen;
