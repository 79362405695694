import React, { useEffect, useMemo, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import QsysTrialScreen from '../qsys-trial-screen';
import QsysZonesScreen from '../qsys-zones-screen';
import useAuth from 'domain/auth/hooks/use-auth';
import useAllQsysDevices from '../hooks/use-all-qsys-devices';
import LoadingProvider from '../../../components/loading-provider/loading-provider';
import QsysMusicScreen from '../qsys-music-screen';
import MusicSearchCollection from '../music/music-search-all';
import SubCategoryPage from '../music/scenes/subcategory-page';
import SingleScenePage from '../music/scenes/single-scene';
import SceneSubCategories from '../music/scenes/scene-subcategories';
import FeaturedPage from '../music/featured-page';
import MusicSearchPage from '../music/music-search-page';
import SingleGenrePage from '../music/genres/single-genre';
import CurrentPlaylistPage from '../music/current-playlist';
import SingleIntensityPage from '../music/intensities/single-intensity';
import SingleVibePage from '../music/vibes/single-vibe';
import NowPlayingWrapper from '../music/now-playing-wrapper';
import DashboardPage from '../dashboard/dashboard-page';
import DeviceInfoPage from '../dashboard/device-info-page';
import HighlightsPage from '../articles/highlights';
import HighlightPage from '../articles/highlight';
import SupportPage from '../support/support-page';
import ProfilePage from '../profile/profile';
import AccountPage from '../profile/account';

const QsysRoutes = () => {
  const { user } = useAuth();
  const { allQsysDevices, loading } = useAllQsysDevices();

  const hasZones =
    allQsysDevices &&
    allQsysDevices.some((device) => device.zones?.length > 0) &&
    user?.profile?.qsysPermit;

  return (
    <LoadingProvider loading={loading}>
      <Switch>
        <Route exact path="/qsys/trial" component={QsysTrialScreen} />

        <Route exact path="/qsys/zones">
          {hasZones ? (
            <QsysZonesScreen qsysDevices={allQsysDevices} />
          ) : (
            <Redirect to="/qsys/trial" />
          )}
        </Route>

        <Route exact path="/qsys/core/:coreId/zone/:id">
          {hasZones ? (
            <NowPlayingWrapper>
              <QsysMusicScreen qsysDevices={allQsysDevices} />
            </NowPlayingWrapper>
          ) : (
            <Redirect to="/qsys/trial" />
          )}
        </Route>

        <Route exact path="/qsys/profile">
          <ProfilePage />
        </Route>

        <Route
          exact
          path="/qsys/support"
          render={(routeProps) => <SupportPage {...routeProps} />}
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/:searchTerm"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <MusicSearchPage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/featured"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <FeaturedPage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/:collectionId"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <MusicSearchCollection {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/scenes/:category/:subcategory"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <SubCategoryPage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/scene/:sceneId"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <SingleScenePage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/scenes/:category"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <SceneSubCategories {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/genres/:genre"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <SingleGenrePage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/vibe/:vibe"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <SingleVibePage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/:collectionId/:playlistId"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <CurrentPlaylistPage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/search/all/intensities/:intensity"
          render={(routeProps) =>
            hasZones ? (
              <NowPlayingWrapper>
                <SingleIntensityPage {...routeProps} />
              </NowPlayingWrapper>
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/dashboard"
          render={(routeProps) =>
            hasZones ? (
              <DashboardPage {...routeProps} />
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/dashboard/information"
          render={(routeProps) =>
            hasZones ? (
              <DeviceInfoPage {...routeProps} />
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id?/highlights"
          render={(routeProps) =>
            hasZones ? (
              <HighlightsPage {...routeProps} />
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id?/highlights/:highlight"
          render={(routeProps) =>
            hasZones ? (
              <HighlightPage {...routeProps} />
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id?/support"
          render={(routeProps) =>
            hasZones ? (
              <SupportPage {...routeProps} />
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/profile"
          render={(routeProps) =>
            hasZones ? (
              <ProfilePage {...routeProps} />
            ) : (
              <Redirect to="/qsys/trial" />
            )
          }
        />

        <Route
          exact
          path="/qsys/core/:coreId/zone/:id/profile/account"
          render={(routeProps) => <AccountPage {...routeProps} />}
        />

        {user && (
          <Route exact path="/qsys">
            {hasZones ? (
              <Redirect to="/qsys/zones" />
            ) : (
              <Redirect to="/qsys/trial" />
            )}
          </Route>
        )}
      </Switch>
    </LoadingProvider>
  );
};

export default QsysRoutes;
